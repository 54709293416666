html {
  font-size: 16px;
  height: 100%;
  width: 100%;
  background-color: #000; }
  @media (min-width: 1920px) {
    html {
      font-size: 24px; } }

body {
  height: 100%;
  width: 100%;
  min-height: 45rem;
  min-width: 80rem;
  margin: 0;
  padding: 0;
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  overflow: hidden; }

body > #root {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(circle at 50% 50%, #394e8f, #000);
  background-repeat: no-repeat;
  overflow: hidden; }

.Page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; }

h1 {
  font-size: 1.6rem;
  font-weight: normal; }

h2 {
  font-size: 1.3rem;
  font-weight: normal; }

h3 {
  font-size: 1.1rem;
  font-weight: normal; }

.ReactModal__Overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 45rem;
  min-width: 80rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent !important;
  z-index: 100;
  overflow: hidden; }

.ReactModal__Content {
  outline: 0; }
