.MainMenuContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  margin: 1rem 0;
  padding: 0 2.5rem;
  white-space: nowrap;
  box-sizing: border-box; }

.MainMenuLogo {
  width: 12rem;
  height: 2.875rem;
  /* 69px */
  padding: 0.5rem 1rem 0;
  box-sizing: border-box; }
  .MainMenuLogo img {
    width: 100%;
    height: auto;
    box-sizing: inherit; }

.MainMenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  box-sizing: border-box; }

.MainMenuItem {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  min-height: 1px; }
  .MainMenuItem span {
    display: inline-block;
    height: 2.625rem;
    line-height: 2.5rem;
    color: #fff;
    font-size: 1.375rem;
    margin: 0 0.9375rem;
    text-transform: uppercase;
    opacity: 0.5;
    font-family: "AmazonFont", "Helvetica Neue", sans-serif;
    border-bottom: solid 0.1875rem transparent; }
  .MainMenuItem.focused {
    border-bottom-color: #61beeb;
    opacity: 1; }
    .MainMenuItem.focused span {
      border-bottom-color: #61beeb;
      opacity: 1; }
  .MainMenuItem:nth-child(4) {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    width: 100%; }
  .MainMenuItem:nth-child(n+5) {
    font-size: 1.125rem; }
