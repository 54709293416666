:local(.PlayerControls) {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 8.125rem; }
  :local(.PlayerControls) .closeButton {
    z-index: 100;
    position: absolute;
    left: -8.75rem;
    top: -0.9375rem; }
  :local(.PlayerControls) .controls {
    z-index: 100;
    height: 5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    left: 50%;
    margin-left: -7.5rem;
    width: 15rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center; }
    :local(.PlayerControls) .controls .tooltip {
      background-color: #fff;
      color: #000;
      font-size: 0.75rem;
      padding: 0.375rem 0.75rem;
      position: relative;
      width: 6.25rem; }
    :local(.PlayerControls) .controls .tooltip:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: #fff;
      border-width: 0.5rem;
      margin-left: -0.5rem; }
  :local(.PlayerControls) .AdditionalControls {
    right: 50%;
    left: auto;
    margin-right: -22.5rem;
    margin-left: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
