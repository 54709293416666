:local(.AtoZMenu) {
  margin-top: 1.875rem; }
  :local(.AtoZMenu) > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 26.25rem; }
    :local(.AtoZMenu) > ul > li {
      margin: 0.125rem;
      width: 3.6875rem;
      height: 3.5rem;
      background-color: rgba(255, 255, 255, 0.08);
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center; }
      :local(.AtoZMenu) > ul > li > label {
        opacity: 0.9;
        color: #fff;
        font-family: "AmazonFont", "Helvetica Neue", sans-serif;
        font-size: 1.875rem; }
      :local(.AtoZMenu) > ul > li.wider {
        width: 7.625rem; }
        :local(.AtoZMenu) > ul > li.wider > label {
          font-size: 1.4375rem; }
      :local(.AtoZMenu) > ul > li.focused {
        width: 3.9375rem;
        height: 3.75rem;
        margin: 0;
        background-color: #61beeb; }
        :local(.AtoZMenu) > ul > li.focused > label {
          color: #000; }
        :local(.AtoZMenu) > ul > li.focused.wider {
          width: 7.875rem; }
