:local(.PlaylistMenuItem) {
  position: relative;
  height: 5.625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0.9375rem 1.875rem;
  box-sizing: border-box;
  overflow: hidden; }
  :local(.PlaylistMenuItem) * {
    pointer-events: none; }
  :local(.PlaylistMenuItem).focused {
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.5); }
  :local(.PlaylistMenuItem) > .image {
    position: relative;
    width: 3.75rem;
    height: 3.75rem;
    margin-right: 1.5625rem; }
  :local(.PlaylistMenuItem) > .image > svg {
    width: 3.75rem;
    height: 3.75rem; }
  :local(.PlaylistMenuItem) > .info {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column; }
    :local(.PlaylistMenuItem) > .info > .title {
      opacity: 0.9;
      font-family: "AmazonFont", "Helvetica Neue", sans-serif;
      font-size: 1.75rem;
      color: #fff;
      max-width: 35.4375rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    :local(.PlaylistMenuItem) > .info > .subtitle {
      opacity: 0.6;
      font-family: "AmazonFont", "Helvetica Neue", sans-serif;
      font-size: 1.25rem;
      color: #fff;
      max-width: 35.4375rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  :local(.PlaylistMenuItem).isExplicit {
    opacity: 0.3;
    background-color: #fdfdfd2e;
    pointer-events: none; }
    :local(.PlaylistMenuItem).isExplicit > p {
      font-size: 0.9375rem;
      padding: 0.3125rem 0.625rem;
      border: solid 0.125rem;
      margin-left: 90%; }
  :local(.PlaylistMenuItem)::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, right top, left top, from(#61beeb), to(#3f86c3));
    background-image: linear-gradient(to left, #61beeb, #3f86c3);
    -webkit-transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0;
    pointer-events: none; }
  :local(.PlaylistMenuItem).focused::before {
    -webkit-transition-duration: 100ms;
            transition-duration: 100ms;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  :local(.PlaylistMenuItem).focused > .info > .title {
    color: #000; }
  :local(.PlaylistMenuItem).focused > .info > .subtitle {
    opacity: 0.55;
    color: #000; }
