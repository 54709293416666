.Playback {
  text-align: center;
  background-size: cover; }

.Playback::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  pointer-events: none; }

.Playback::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 19.4375rem;
  background-image: url("../../assets/images/gradients/playback-gradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none; }

.Playback-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.2;
  -webkit-filter: blur(0.625rem);
          filter: blur(0.625rem); }

.PlaybackResponsePortal {
  pointer-events: none; }

.PlaybackResponse {
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.5);
  border: solid 0.0625rem rgba(83, 174, 245, 0.9);
  background-color: #101527;
  padding: 0.9375rem 1.5625rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none; }

.PlaybackResponse > label {
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  color: #fff; }
