.emptyState {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 18%; }
  .emptyState .emptyState-icon {
    display: block;
    width: 24rem;
    height: 8.2rem;
    object-fit: contain; }
  .emptyState .message {
    margin: 1.5625rem auto;
    width: 32rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.33; }
