:local(.TrackInfo) {
  position: absolute;
  top: 3.75rem;
  left: 12.5rem;
  width: 55rem;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center; }
  :local(.TrackInfo) img {
    height: 18.75rem;
    width: 18.75rem;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5); }
  :local(.TrackInfo) .TrackDeets {
    margin-top: 1.5625rem;
    width: 55rem;
    height: 15.9375rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    :local(.TrackInfo) .TrackDeets .titleSection {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center; }
      :local(.TrackInfo) .TrackDeets .titleSection .title {
        display: block;
        opacity: 0.9;
        font-family: "AmazonFont", "Helvetica Neue", sans-serif;
        font-size: 2.375rem;
        color: #fff;
        overflow: hidden;
        max-height: 5.625rem; }
      :local(.TrackInfo) .TrackDeets .titleSection .subtitle {
        display: block;
        margin-top: 0.3125rem;
        opacity: 0.6;
        color: #fff;
        font-family: "AmazonFont", "Helvetica Neue", sans-serif;
        font-size: 1.25rem;
        overflow: hidden;
        max-height: 3rem; }
  :local(.TrackInfo) .NextButton {
    display: inline-block;
    height: 1.875rem;
    line-height: 1.875rem;
    padding: 0 0.625rem;
    border: 0.0625rem solid #fff; }
  :local(.TrackInfo) .NextButton.focused {
    background: dodgerblue;
    border: 0.0625rem solid dodgerblue; }
