.SettingsWindow {
  width: 100%;
  height: 100%;
  background-color: rgba(16, 21, 39, 0.8); }

.SettingsList {
  position: absolute;
  top: 6.25rem;
  right: 6.25rem; }

.SettingsItem {
  margin: 0.625rem auto;
  font-size: 1.875rem; }

.SettingsItem.focused {
  text-decoration: underline; }

.termsOfUse {
  width: 70%;
  line-height: 1.5625rem; }

.version {
  position: absolute;
  right: 6.25rem;
  bottom: 6.25rem; }
