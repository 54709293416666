.Home-scrollable {
  position: relative;
  height: 40.0625rem;
  width: 77.5rem;
  padding-left: 2.5rem;
  overflow: hidden; }

.Home-scrollable.faded {
  opacity: 0.2; }

.Home-scrollable > div.ListMenu {
  padding-bottom: 9.375rem; }
