:local(.Catalog) {
  margin: 0 3.75rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 73.125rem; }
  :local(.Catalog) > .info {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 28.125rem;
    margin: 2.8125rem 0; }
    :local(.Catalog) > .info .infoHeader {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 4.25rem; }
      :local(.Catalog) > .info .infoHeader .closeButton:hover::before {
        -webkit-transition-duration: 300ms;
                transition-duration: 300ms;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1; }
      :local(.Catalog) > .info .infoHeader .title {
        font-family: "AmazonFont", "Helvetica Neue", sans-serif;
        font-size: 2rem;
        color: #fff;
        padding-left: 2.25rem; }
    :local(.Catalog) > .info > .image {
      width: 21.875rem;
      min-width: 21.875rem;
      height: 21.875rem;
      min-height: 21.875rem;
      box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.5); }
