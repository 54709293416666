@keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3); }
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1); } }

@-webkit-keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3); }
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1); } }

.line-scale-pulse-out-rapid > div {
  background-color: #fff;
  width: 0.25rem;
  height: 2.1875rem;
  border-radius: 0.125rem;
  margin: 0.125rem;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: line-scale-pulse-out-rapid 0.9s cubic-bezier(0.11, 0.49, 0.38, 0.78) -0.5s infinite;
  animation: line-scale-pulse-out-rapid 0.9s cubic-bezier(0.11, 0.49, 0.38, 0.78) -0.5s infinite; }

.line-scale-pulse-out-rapid > div:nth-child(2),
.line-scale-pulse-out-rapid > div:nth-child(4) {
  -webkit-animation-delay: -0.25s !important;
  animation-delay: -0.25s !important; }

.line-scale-pulse-out-rapid > div:first-child,
.line-scale-pulse-out-rapid > div:nth-child(5) {
  -webkit-animation-delay: 0s !important;
  animation-delay: 0s !important; }

.loader-hidden {
  display: none; }

.loader-active {
  display: block; }

.page-loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 25%; }

.disableMargin {
  margin: 0; }

.results-loading {
  margin: auto 0 !important;
  width: 41.875rem !important; }
