.HomeMenu {
  margin-left: 0.9375rem;
  width: 100%;
  height: 18.75rem;
  overflow: hidden; }

.HomeMenu h1 {
  opacity: 0.75;
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1.875rem;
  word-wrap: break-word;
  max-width: 32.8125rem; }
