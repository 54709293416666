:local(.ProgressBar) {
  width: 80rem;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 2; }
  :local(.ProgressBar) .barWrapper {
    width: 60rem;
    position: relative; }
    :local(.ProgressBar) .barWrapper .bar {
      width: 100%;
      height: 4rem;
      position: absolute;
      bottom: 2rem;
      z-index: 99; }
      :local(.ProgressBar) .barWrapper .bar::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 0.375rem;
        left: 0;
        top: 50%;
        margin-top: -0.1875rem;
        opacity: 0.05;
        border-radius: 0.3125rem;
        background-color: #fff;
        pointer-events: none; }
    :local(.ProgressBar) .barWrapper .user-indicator,
    :local(.ProgressBar) .barWrapper .indicator,
    :local(.ProgressBar) .barWrapper .buffered {
      height: 0.375rem;
      border-radius: 0.3125rem;
      position: absolute;
      bottom: 3.75rem;
      left: 0;
      pointer-events: none; }
    :local(.ProgressBar) .barWrapper .indicator {
      background: #61beeb; }
    :local(.ProgressBar) .barWrapper .buffered {
      background: #7fa3ca;
      opacity: 0.4; }
    :local(.ProgressBar) .barWrapper .user-indicator {
      opacity: 0.4;
      background: #ff2300; }
  :local(.ProgressBar) .playTimeContainer {
    z-index: 100;
    position: absolute;
    left: 0;
    bottom: 5rem;
    width: 60rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    pointer-events: none; }
    :local(.ProgressBar) .playTimeContainer .playTimeDisplay {
      opacity: 0.6;
      font-family: "AmazonFont", "Helvetica Neue", sans-serif;
      font-size: 1.125rem;
      color: #fff; }
