.HomeMenuHorizontalLoadingMenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 0;
  margin-left: 1.625rem;
  width: 100%; }

.HomeMenuHorizontalLoadingMenuWrapper {
  position: relative;
  margin-left: -1.25rem; }

.IncArrow {
  position: absolute;
  top: 0;
  height: 12.5625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1em; }
  .IncArrow.display .incrementArrow {
    opacity: 1;
    pointer-events: auto; }
  .IncArrow:hover .incrementArrow:hover {
    background-image: -webkit-gradient(linear, right top, left top, from(#61beeb), to(#3f86c3));
    background-image: linear-gradient(to left, #61beeb, #3f86c3); }
  .IncArrow .incrementArrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-sizing: border-box;
    background: #000;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    width: 3.75rem;
    -webkit-transition: opacity 500ms 200ms;
    transition: opacity 500ms 200ms;
    opacity: 0;
    pointer-events: none; }
    .IncArrow .incrementArrow.right {
      border-right: 0.25rem solid #61beeb; }
    .IncArrow .incrementArrow.left {
      border-left: 0.25rem solid #3f86c3; }

.HomeMenuHorizontalLoadingMenuDecrement {
  left: 0.25rem;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 1em; }

.HomeMenuHorizontalLoadingMenuIncrement {
  left: 74.0625rem;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .searchPage .HomeMenuHorizontalLoadingMenuIncrement {
    left: 46.125rem; }
