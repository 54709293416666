.ErrorModalActions {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex; }

.UnlimitedModal {
  width: 25rem;
  min-height: 4.375rem;
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.5);
  border: solid 0.0625rem rgba(83, 174, 245, 0.9);
  background-color: #101527;
  padding: 1.5625rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  outline: none; }

.UnlimitedModal > .Title {
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1.625rem;
  color: #fff;
  margin-bottom: 0.625rem; }

.UnlimitedModal > label {
  text-align: center; }

.UnlimitedModal > .Subtitle {
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  color: #fff;
  text-align: center;
  margin-bottom: 1.25rem; }

.ActionModalButton {
  width: 8.75rem;
  height: 2.5rem;
  margin: 0.3125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #282C3D;
  color: #fff;
  text-transform: uppercase; }

.ActionModalButton:hover,
.ActionModalButton.focused {
  background-image: -webkit-gradient(linear, right top, left top, from(#61beeb), to(#3f86c3));
  background-image: linear-gradient(to left, #61beeb, #3f86c3);
  color: #000; }

.ActionModalButton > label {
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-align: center; }
