:local(.Search) {
  margin-left: 3.4375rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 6250rem;
  position: relative; }
  :local(.Search) .search-info {
    width: 26.875rem;
    margin-top: 5.625rem;
    margin-right: 1.875rem; }
    :local(.Search) .search-info .field {
      width: 23.75rem;
      height: 3.75rem;
      background-color: rgba(255, 255, 255, 0.08);
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      padding-left: 4.6875rem;
      padding-right: 0.3125rem;
      box-sizing: border-box;
      position: relative; }
      :local(.Search) .search-info .field > svg {
        position: absolute;
        left: 1.5625rem;
        top: 50%;
        width: 1.875rem;
        height: 1.875rem;
        margin-top: 0.2rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        pointer-events: none; }
      :local(.Search) .search-info .field > .search-form {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 2rem; }
        :local(.Search) .search-info .field > .search-form label {
          display: block;
          color: #fff;
          font-family: "AmazonFont", "Helvetica Neue", sans-serif;
          font-size: 1.75rem;
          text-align: right;
          white-space: nowrap;
          text-align: left;
          text-overflow: ellipsis;
          max-width: 17.5rem;
          height: 2rem;
          line-height: 2rem;
          overflow: hidden; }
        :local(.Search) .search-info .field > .search-form .blinker {
          border-right: 0.25rem solid #fff;
          margin-left: 0.125rem;
          height: 2rem;
          -webkit-animation: blink 1s steps(5, start) infinite;
          animation: blink 1s steps(5, start) infinite; }
  :local(.Search) .search-result-wrapper {
    position: absolute;
    left: 25.75rem;
    z-index: 0;
    overflow: hidden; }
    :local(.Search) .search-result-wrapper .Home-scrollable {
      padding-left: 1.25rem; }

.searchPage .scrolledButtons {
  margin-left: -12.875rem; }

@keyframes blink {
  to {
    visibility: hidden; } }

@-webkit-keyframes blink {
  to {
    visibility: hidden; } }
