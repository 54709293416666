.HomeMenuCard {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20.625rem;
          flex: 0 0 20.625rem;
  width: 20.625rem;
  height: 11.375rem;
  background-color: rgba(255, 255, 255, 0.08);
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  font-size: 0.8rem;
  padding: 1.0625rem;
  text-align: center;
  float: left;
  box-sizing: border-box;
  margin: 0.625rem;
  position: relative;
  -webkit-transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden; }
  .HomeMenuCard * {
    pointer-events: none; }

.HomeMenuCard > div.itemLabel {
  width: 50%;
  padding-left: 1.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column; }

.HomeMenuCard > div.itemLabel > .title {
  text-align: left;
  opacity: 0.9;
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  max-height: 6.375rem;
  line-height: 1.25rem; }

.HomeMenuCard > div.itemLabel--subtitle > .subtitle {
  text-align: left;
  opacity: 0.6;
  font-family: "AmazonFont", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.1875rem;
  line-height: 1.125rem;
  padding-top: 0.3125rem;
  max-width: 100%; }

.HomeMenuCard > div.itemLabel > div.goUnlimited {
  text-align: left; }

.HomeMenuCard > img {
  display: block;
  float: left;
  width: 50%;
  height: 100%; }

.HomeMenuCard.isExplicit {
  opacity: 0.3;
  background-color: #fdfdfd2e; }

.HomeMenuCard.isExplicit > p {
  font-size: 3.25rem;
  padding: 0.3125rem 0.625rem;
  border: solid 0.125rem;
  margin-left: 70%; }

/*  Focused states */
.HomeMenuCard.focused {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 0 0.625 0 rgba(0, 0, 0, 0.5);
  background-image: -webkit-gradient(linear, right top, left top, from(#61beeb), to(#3f86c3));
  background-image: linear-gradient(to left, #61beeb, #3f86c3);
  color: #252d41; }

.HomeMenuCard.focused > div.itemLabel > .title {
  color: #000; }

.HomeMenuCard.focused > div.itemLabel--subtitle > .subtitle {
  opacity: 0.55;
  color: #0d0c0f; }
