:local(.PlayerControlButton) {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 2.25rem;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 2; }
  :local(.PlayerControlButton).disabled {
    opacity: 0.4; }
  :local(.PlayerControlButton).thumbs--disabled:not(.focused) {
    opacity: 0.4; }
  :local(.PlayerControlButton) * {
    pointer-events: none; }
  :local(.PlayerControlButton) svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.25rem;
    height: 2.25rem;
    fill: #fff;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
  :local(.PlayerControlButton)::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2.25rem;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0; }
  :local(.PlayerControlButton).actived, :local(.PlayerControlButton).focused::before {
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  :local(.PlayerControlButton).actived {
    background-color: #fff; }
    :local(.PlayerControlButton).actived svg {
      fill: #000; }
